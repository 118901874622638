

import {computed, defineComponent, onMounted, ref, onUpdated} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {dateConvert} from "@/core/filters/datatime";
import reservationConfirmTypes2 from "@/core/data/reservationConfirmType2";



interface InterfaceVocuher {
    Description: string;
    ManuelEmail: string;
    OperationEmail: string;
}

export default defineComponent({
    name: "reservation-modal",
    components: {
    },

    setup() {


        const arrProductList = ref([]);
        const arrEmailSentsList = ref([]);
        const arrHotelDetails = ref([]);
        const arrCheckedEmails = ref([]);
        const submitButtonRefConfirm = ref<null | HTMLButtonElement>(null);
        const submitButtonRefChange = ref<null | HTMLButtonElement>(null);
        const submitButtonRefCancel = ref<null | HTMLButtonElement>(null);



        function resetElements(prmProductID) {
            InterfaceVocuher.value.Description = "";
            InterfaceVocuher.value.ManuelEmail = "";
            let vvemailCheckedClasses = "input-Select-Reservation-Email";
            const arrCheckedElemnts = document.getElementsByClassName(vvemailCheckedClasses);
            const checkedPriceElement = document.getElementById("inputShowHidePriceInfo"+prmProductID) as HTMLInputElement;
            const checkedAccountElement = document.getElementById("inputShowHideAccountInfo"+prmProductID) as HTMLInputElement;
            for( let i=0; i<arrCheckedElemnts.length; i++){
                let eachCheckedElements = arrCheckedElemnts[i] as HTMLInputElement;
                if(eachCheckedElements.checked){
                    (eachCheckedElements.checked = false);
                }
                if(checkedPriceElement.checked){
                    checkedPriceElement.checked = false
                }
                if(checkedAccountElement.checked){
                    checkedAccountElement.checked = false
                }
            }
        }

        function showComp(ID) {
            const payload = {
                "ReservationID": ID,
                "ProductType": "HOTEL",
            }

            store.dispatch(Actions.RESERVATION_PRODUCTS_CONFIRM_LIST,  payload).then((prmData) => {
                arrProductList.value=prmData.data
                //console.log("ReservationListVoucherModal showComp arrProductList" + JSON.stringify(prmData.data));

                const payloadEmailSents = {
                    Type: "HOTELVOUCHER",
                    TypeID: (arrProductList.value as any)[0].ID,
                    TypeCode:(arrProductList.value as any)[0].VoucherNo,
                }
                store.dispatch(Actions.EMAIL_SENTS_LIST,  payloadEmailSents).then((prmData) => {
                    arrEmailSentsList.value=prmData.data;

                    resetElements((arrProductList.value as any)[0].ID);
                })

                store.dispatch(Actions.HOTEL_DETAILS, {ID: (arrProductList.value as any)[0].RemarksINT1}).then((prmData) => {
                    arrHotelDetails.value=prmData
                })
            })

        }


        function getReservationEmail(prmResEmail) {
            let rv = [prmResEmail];

            return rv;
        }

        const InterfaceVocuher = ref<InterfaceVocuher>({
            Description: "",
            ManuelEmail: "",
            OperationEmail: "operasyon@cyprusroyal.com",
        });

        function getIsChecked(prmID) {
            //let vvShowAccount = "inputShowHideAccountInfo" + prmProductID;
            const inpEl = document.getElementById(prmID) as HTMLInputElement;
            if(inpEl.checked){
                return "true";
            }
            return "false";
        }



        function clickConfirm(prmProductID, prmEmailKind){

            if (!submitButtonRefConfirm.value && !submitButtonRefChange.value && !submitButtonRefCancel.value) {
                return;
            }

            let arrIds = [];
            let arrEmails = [];
            arrIds.push(prmProductID);
            arrCheckedEmails.value=[];

            let vvClassName = "inputSelectReservationEmail" + prmProductID;
            const arrElements = document.getElementsByClassName(vvClassName);
            let vvID = "inputSelectManuelEmail" + prmProductID;

            let vvOperationMail = "inputSelectOperationEmail" + prmProductID;
            const operationElements = document.getElementById(vvOperationMail) as HTMLInputElement;

            if(operationElements.checked){
                arrEmails.push(InterfaceVocuher.value.OperationEmail);
            }

            const manuelElements = document.getElementById(vvID) as HTMLInputElement;


            if(manuelElements.checked){
                arrEmails.push(InterfaceVocuher.value.ManuelEmail);
            }


            for(let i=0; i<arrElements.length; i++){
                let eachElemnt = arrElements[i] as HTMLInputElement;
                if (eachElemnt.checked) {
                    arrCheckedEmails.value.push(getProductByProductID(eachElemnt.getAttribute("productID")));
                    console.log("ReservationListVoucherModal clickConfirm " +JSON.stringify(arrCheckedEmails.value));
                    arrEmails.push(eachElemnt.getAttribute("email"));
                }

            }



            if(arrEmails.length>0){
                console.log("ReservationModal clickConfirm v1");
                // store.dispatch(Actions.EXPORT_TO_PDF, {TemplateCode: "HotelVoucher", Ids: arrIds}).then((prmData) => {
                //store.dispatch(Actions.EXPORT_TO_PDF, {TemplateCode_Email: "HotelVoucher", TemplateCode_Pdf: "HotelVoucher_Pdf", Ids: arrIds}).then((prmData) => {
                //store.dispatch(Actions.EXPORT_TO_PDF, {TemplateCode: "HotelVoucher", Ids: arrIds, testEmail: "hasanporklu@gmail.com" }).then((prmData) => {
                store.dispatch(Actions.EXPORT_TO_PDF, {
                    TemplateCode_Email: "HotelVoucher_Email", TemplateCode_Pdf: "HotelVoucher_Pdf",
                    Ids: arrIds, Emails: arrEmails, testEmails: ["operasyon@cyprusroyal.com"] ,
                    EmailKind:prmEmailKind, Description:InterfaceVocuher.value.Description,
                    IsShowAccount: getIsChecked("inputShowHideAccountInfo" + prmProductID), IsShowPayment:getIsChecked("inputShowHidePriceInfo" + prmProductID),
                }).then((prmData)=>{
                    if(prmEmailKind==="CONFIRM"){
                        submitButtonRefConfirm.value.disabled = false;
                        submitButtonRefConfirm.value.setAttribute("data-kt-indicator", "off");
                    }
                    if(prmEmailKind==="CHANGE"){
                        submitButtonRefChange.value.disabled = false;
                        submitButtonRefChange.value.setAttribute("data-kt-indicator", "off");
                    }
                    if(prmEmailKind==="CANCEL"){
                        submitButtonRefCancel.value.disabled = false;
                        submitButtonRefCancel.value.setAttribute("data-kt-indicator", "off");
                    }
                    if (prmData.data!==undefined) {
                        let isErrorExist = false;
                        let vvSendEmail = "";

                        for (let i=0; i<prmData.data.length; i++) {
                            let vvEach = prmData.data[i];
                            console.log(vvEach);
                            console.log(vvEach.result);
                            if (vvEach.result!=="success") {
                                isErrorExist = true;
                            } else {
                                vvSendEmail = vvEach.email;
                            }
                        }
                        let vvText = "Tebrikler. "+vvSendEmail+" adresine başarılı bir şekilde "+prmData.data.length+" adet eposta gönderildi";

                        if (!isErrorExist) {
                            Swal.fire({
                                text: vvText, confirmButtonText: "Geri Dön!",
                                icon: "success", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-primary",},
                            }).then(function () {
                                location.reload();
                            });
                        } else {
                            libTools.method.swalError("Tamam", "Bazı Email bilgileri gönderilemedi");
                        }



                    }
                }).catch((prmData) => {
                    console.log("ReservationModal clickConfirm v8");
                    libTools.method.swalError("Tamam", "Hata Oluştu");
                });
                if(prmEmailKind==="CONFIRM"){
                    submitButtonRefConfirm.value.disabled = false;
                    submitButtonRefConfirm.value.setAttribute("data-kt-indicator", "on");
                }
                if(prmEmailKind==="CHANGE"){
                    submitButtonRefChange.value.disabled = false;
                    submitButtonRefChange.value.setAttribute("data-kt-indicator", "on");
                }
                if(prmEmailKind==="CANCEL"){
                    submitButtonRefCancel.value.disabled = false;
                    submitButtonRefCancel.value.setAttribute("data-kt-indicator", "on");
                }
            }else{
                libTools.method.swalError("Tamam", "Email seçiniz.");
            }




        }

        /*    function getVoucherByProductID (prmProductID){
              const confirmResults = arrProductList.value.filter(obj =>{
                return obj.RemarksINT1 === prmProductID + "";
              })
              return confirmResults;
            };*/

        function getProductByProductID(prmProductID) {
            for (let i=0; i<arrProductList.value.length; i++){
                let eachProduct = arrProductList.value[i];
                if (eachProduct.ID === prmProductID){
                    return  eachProduct;
                }
            }
            return null;
        }


        return {
            dateConvert,
            arrProductList,
            arrEmailSentsList,
            arrHotelDetails,
            clickConfirm,
            showComp,
            getReservationEmail,
            InterfaceVocuher,
            reservationConfirmTypes2,
            libTools,
            //getVoucherByProductID,
            submitButtonRefConfirm,
            submitButtonRefChange,
            submitButtonRefCancel,
        }

    },

});



import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import MasterConfig from "@/core/config/MasterConfig";
import { dateConvert } from "@/core/filters/datatime"
import {Field} from "vee-validate";
import {commonf} from "@/core/filters/common";
import {Turkish} from "flatpickr/dist/l10n/tr";
import flatpickr from "flatpickr";
import ReservationPreview from "@/components/reservations/ReservationPreview.vue";
import NewReservationModal from "@/components/general-modals/NewReservationModal.vue";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import ReservationListVoucherModal from "@/components/general-modals/ReservationListVoucherModal.vue";

interface srcItem {
    srcFromDate: string;
    srcToDate: string;
    srcStartDate1: string;
    srcStartDate2: string;
    srcFinishDate1: string;
    srcFinishDate2: string;
    srcAccount: number;
    srcDepartment: number,
    srcMarket: number;
    srcPNR: string;
    srcVoucherNo: string;
    srcAccountVoucherNo: string;
    srcName: string;
    srcLastName: string;
}

export default defineComponent({
    name: "hotel-list",
    components: {
        Field,
        ReservationPreview,
        //ReservationAddNewModal,
        NewReservationModal,
        ReservationListVoucherModal,
    },
    data: function () {
        return {
            imgUrl: '',
            searchName: '',
            sortField: '',
            sortType: '',
            filterField: ['Title'],
            filterFieldName: ['Voucher No','Satış','Cari Hesap'],
        }
    },
    computed: {
        /*filteredTableList: function () {
          if (this.myList.data !== undefined) {
            return this.myList.data;
          } else {
            return [];
          }
        },
          */

        filteredTableList: function () {
            if (this.arrReservationList !== undefined){
                console.log("reservation list filtertable list  v1");
                return this.arrReservationList.filter(filteredList =>
                        (filteredList.AccountTitle !== undefined && !(filteredList.AccountTitle).toLowerCase().indexOf(this.searchName.toLowerCase()))||
                        (filteredList.VoucherNo !== undefined && !(filteredList.VoucherNo).toLowerCase().indexOf(this.searchName.toLowerCase()))||
                        (filteredList.HotelTitle !== undefined && !(filteredList.HotelTitle).toLowerCase().indexOf(this.searchName.toLowerCase()))
                    //  (filteredList.ProductTitle !== undefined && !(( filteredList.ProductTitle as any).HOTEL).toLowerCase().indexOf(this.searchName.toLowerCase()))

                    //!(systemuser.Lastname).toLowerCase().indexOf(this.searchName.toLowerCase()) ||
                    //!(systemuser.Username).toLowerCase().indexOf(this.searchName.toLowerCase()) ||
                    //!(systemuser.Email).toLowerCase().indexOf(this.searchName.toLowerCase()) ||
                    //!(systemuser.Mobile).toLowerCase().indexOf(this.searchName.toLowerCase())

                );
            }else{
                return [];
            }
        },

    },
    props: {
        widgetClasses: String,
    },
    methods: {
        ...dateConvert.method,

        sortData(attr, data) {
            if (this.sortField == '') {
                this.sortType = "asc";
            } else {
                this.sortType = this.sortField == attr && this.sortType == 'asc' ? "desc" : "asc";
            }
            this.sortField = attr;

            if (this.sortType == 'asc' || this.sortType == 'desc') {
                this[data].sort((a, b) => {
                    let fa = '';
                    let fb = '';
                    if (typeof (attr) == 'string') {
                        if (this.sortType == 'asc') {
                            fa = a[attr].toLowerCase();
                            fb = b[attr].toLowerCase();
                        } else {
                            fb = a[attr].toLowerCase();
                            fa = b[attr].toLowerCase();
                        }
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    } else {
                        return this.sortType == 'asc' ? (a.Mobile - b.Mobile) : (b.Mobile - a.Mobile);
                    }
                });
            }
        }
    },
    setup() {

        const srcItem = ref<srcItem>({
            srcFromDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
            srcToDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
            srcStartDate1: "",
            srcStartDate2: "",
            srcFinishDate1: "",
            srcFinishDate2: "",
            srcAccount: 0,
            srcDepartment: 0,
            srcMarket: 0,
            srcPNR: "",
            srcVoucherNo: "",
            srcAccountVoucherNo: "",
            srcName: "",
            srcLastName: "",
        });

        onMounted(()=>{
            setDatePicker();
            store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});
            store.dispatch(Actions.MARKETS_LIST_CACHE, {});

            click_Search();

        });

        const arrReservationList = ref([]);

        function fitReservationList(prmList) {
            console.log("ReservationList fitReservationList started");
            for (let i=0; i<prmList.length; i++ ){
                let each = prmList[i];
                each.HotelTitle = getProductTitle(each)
                arrReservationList.value.push(each);
                console.log("ReservationList fitReservationList " + JSON.stringify(each));
            }

        }

        const myList = computed(() => {
            console.log("ReservationList myList v1");
            let dataComputed = store.getters.reservationsList2
            if (dataComputed!=undefined && dataComputed.data!==undefined) {
                console.log("ReservationList myList v2");
                fitReservationList(dataComputed.data);
                console.log("ReservationList myList v3");
            }
            return dataComputed.data;
        });

        const myParams = computed(() => {
            return store.getters.generalParams;
        });

        const myDepartments = computed(() => {
            return store.getters.DepartmentsList;
        });

        const myMarkets = computed(() => {
            return store.getters.MarketsListCache;
        });

        const myAccountList = computed(() => {
            return store.getters.accountList;
        });

        const myAccountsTitle = computed(() => {
            let tmpArray = [];
            if (myAccountList.value.data !== undefined) {
                for (var i of myAccountList.value.data) {
                    tmpArray.push(i.Title);
                }
            }
            return tmpArray;
        });

        const getAccounts = () => {
            let myAccounts = document.getElementById("srcAccounts") as HTMLInputElement;
            let myPayload = {};
            if (myAccounts.value.length == 3) {
                myPayload = {
                    'Title': myAccounts.value
                }
                store.dispatch(Actions.ACCOUNT_LIST_ALL, myPayload);
            }
        }
        const filterAccounts = (val) => {
            if (myAccountList.value.data != null) {
                return (myAccountList.value.data).filter(Items =>
                    (Items.Title == val)
                );
            } else {
                return true;
            }
        };

        var fpickStartDate1;
        var fpickStartDate2;
        var fpickFinishDate1;
        var fpickFinishDate2;

        function setDatePicker() {
            let tmpSrcFromDate = flatpickr("#srcFromDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
            let tmpSrcToDate = flatpickr("#srcToDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});

            fpickStartDate1 = flatpickr("#scr_ReservationsList_StartDate1", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
            fpickStartDate2 = flatpickr("#scr_ReservationsList_StartDate2", {"locale": Turkish, dateFormat: "d-m-Y (D)", });

            fpickFinishDate1 = flatpickr("#scr_ReservationsList_FinishDate1", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
            fpickFinishDate2 = flatpickr("#scr_ReservationsList_FinishDate2", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
        }

        function getProductTitle(prmEach) {

            if ((prmEach as any).ProductTitle!==null) {
                //console.log("getProductTitle ProductTitle: "+(prmEach as any).ProductTitle);
                let objProductTitle = JSON.parse((prmEach as any).ProductTitle);
                if ((objProductTitle as any).HOTEL!==undefined) {
                    return (objProductTitle as any).HOTEL[0];
                }
            }

            return "";
        }

        function getProductCounts(prmEach, prmType) {
            if ((prmEach as any).ProductCounts!==null) {
                let objParsed = JSON.parse((prmEach as any).ProductCounts);

                if (prmType==="FLIGHT" && (objParsed as any).FLIGHT!==undefined) {
                    return Number((objParsed as any).FLIGHT);
                }
                if (prmType==="HOTEL" && (objParsed as any).HOTEL!==undefined) {
                    return Number((objParsed as any).HOTEL);
                }
                if (prmType==="TRANSFER" && (objParsed as any).TRANSFER!==undefined) {
                    return Number((objParsed as any).TRANSFER);
                }
            }

            return "";
        }


        const click_Search = () => {


            let myAccount = document.getElementById("srcAccounts") as HTMLInputElement;
            const payload1 = {
                ProcessDateStart: srcItem.value.srcFromDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcFromDate) : "",
                ProcessDateFinish: srcItem.value.srcToDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcToDate) : "",
                StartDate1: libTools.method.getSelectedDateFromFlatPicker(fpickStartDate1, 0),
                StartDate2: libTools.method.getSelectedDateFromFlatPicker(fpickStartDate2, 0),
                FinishDate1: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDate1, 0),
                FinishDate2: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDate2, 0),
                VoucherNo: srcItem.value.srcVoucherNo,
                AccountVoucherNo: srcItem.value.srcAccountVoucherNo,
                Department: srcItem.value.srcDepartment,
                Market: srcItem.value.srcMarket,
                AccountID: myAccount.value ? filterAccounts(myAccount.value)[0].ID : "",
                PNR: srcItem.value.srcPNR,
                PaxName: srcItem.value.srcName,
                PaxLastName: srcItem.value.srcLastName,
                //ProductType: "FLIGHT",
                is_active: "active",
            };
            console.log("click_Search payload1: "+JSON.stringify(payload1));
            store.dispatch(Actions.RESERVATIONS_LIST2, payload1);
        };


        let refComp_ReservationVoucherModal = ref(null);

        function clickShowVoucher(ID) {
            (refComp_ReservationVoucherModal.value as any).showComp(ID);
        }

        return {
            dateConvert,
            commonf,
            libTools,
            myParams,
            MasterConfig,
            srcItem,
            myAccountList,
            myAccountsTitle,
            getAccounts,
            click_Search,
            myDepartments,
            myMarkets,
            myList,
            getProductTitle,
            getProductCounts,
            clickShowVoucher,
            refComp_ReservationVoucherModal,
            arrReservationList,
        }
    },
});
